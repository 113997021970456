define("discourse/plugins/discourse-token-gate/discourse/services/meta-mask", ["exports", "@ember/service", "discourse/lib/ajax", "discourse/lib/environment", "discourse-i18n"], function (_exports, _service, _ajax, _environment, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MetaMaskService extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    get metaMaskProvider() {
      return window.ethereum;
    }
    get installed() {
      return (0, _environment.isTesting)() || this.metaMaskProvider?.isMetaMask;
    }
    #hexChainId(chainId) {
      return `0x${chainId.toString(16)}`;
    }
    async ensureChain(config) {
      const chainId = this.#hexChainId(config.chain_id);
      try {
        await this.metaMaskProvider.request({
          method: "wallet_switchEthereumChain",
          params: [{
            chainId
          }]
        });
      } catch (error) {
        if (error.code === 4902) {
          await this.addChain(config);
          await this.metaMaskProvider.request({
            method: "wallet_switchEthereumChain",
            params: [{
              chainId
            }]
          });
        } else {
          throw error;
        }
      }
    }
    async addChain(config) {
      const chainParams = {
        chainId: this.#hexChainId(config.chain_id),
        chainName: config.blockchain,
        rpcUrls: [config.rpc_url],
        nativeCurrency: {
          name: config.native_currency_name,
          symbol: config.native_currency_symbol,
          decimals: config.native_currency_decimals
        }
      };
      if (config.block_explorer_url) {
        chainParams.blockExplorerUrls = [config.block_explorer_url];
      }
      await this.metaMaskProvider.request({
        method: "wallet_addEthereumChain",
        params: [chainParams]
      });
    }
    async verify(chainId) {
      if (!this.installed) {
        this.dialog.alert((0, _discourseI18n.i18n)("web3.meta_mask.not_installed"));
        return;
      }
      try {
        const config = JSON.parse(this.siteSettings.web3_tokens).find(token => token.chain_id === chainId);
        await this.ensureChain(config);
        const [account] = await this.metaMaskProvider.request({
          method: "eth_requestAccounts"
        });
        const {
          message
        } = await (0, _ajax.ajax)("/web3/signing_message", {
          type: "PUT"
        });
        const signature = await this.metaMaskProvider.request({
          method: "personal_sign",
          params: [message, account]
        });
        await (0, _ajax.ajax)("/web3/verify_public_key", {
          type: "PUT",
          data: {
            signature,
            public_key: account,
            chain_id: chainId
          }
        });
        window.location.reload();
      } catch (error) {
        this.dialog.alert((0, _discourseI18n.i18n)("web3.verification_failed", {
          error: error.message
        }));
        throw error;
      }
    }
  }
  _exports.default = MetaMaskService;
});
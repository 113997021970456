define("discourse/plugins/discourse-token-gate/discourse/controllers/preferences-wallets", ["exports", "@glimmer/tracking", "@ember/controller", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-i18n"], function (_exports, _tracking, _controller, _object, _service, _ajax, _ajaxError, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PreferencesWalletsController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "walletConnect", [_service.service]))();
    #walletConnect = (() => (dt7948.i(this, "walletConnect"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "metaMask", [_service.service]))();
    #metaMask = (() => (dt7948.i(this, "metaMask"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "isUpdating", [_tracking.tracked], function () {
      return false;
    }))();
    #isUpdating = (() => (dt7948.i(this, "isUpdating"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "tokens", [_tracking.tracked], function () {
      return JSON.parse(this.siteSettings.web3_tokens) || [];
    }))();
    #tokens = (() => (dt7948.i(this, "tokens"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "userTokens", [_tracking.tracked], function () {
      return [];
    }))();
    #userTokens = (() => (dt7948.i(this, "userTokens"), void 0))();
    static #_9 = (() => dt7948.g(this.prototype, "availableChains", [_tracking.tracked], function () {
      return JSON.parse(this.siteSettings.web3_tokens).map(token => {
        return {
          chainId: token.chain_id,
          name: token.blockchain
        };
      }) || [];
    }))();
    #availableChains = (() => (dt7948.i(this, "availableChains"), void 0))();
    static #_10 = (() => dt7948.g(this.prototype, "selectedChainId", [_tracking.tracked], function () {
      return this.availableChains[0]?.chainId;
    }))();
    #selectedChainId = (() => (dt7948.i(this, "selectedChainId"), void 0))();
    static #_11 = (() => dt7948.g(this.prototype, "availableTokens", [_tracking.tracked], function () {
      return [];
    }))();
    #availableTokens = (() => (dt7948.i(this, "availableTokens"), void 0))();
    get isMetaMaskInstalled() {
      return this.metaMask.installed;
    }
    selectChain(event) {
      this.selectedChainId = parseInt(event.target.value, 10);
    }
    static #_12 = (() => dt7948.n(this.prototype, "selectChain", [_object.action]))();
    async verifyConnectedMetaMaskAccount() {
      if (!this.tokens.length) {
        this.dialog.alert((0, _discourseI18n.i18n)("web3.preferences.no_tokens_configured"));
        return;
      }
      this.isUpdating = true;
      try {
        await this.metaMask.verify(this.selectedChainId);
      } catch (error) {
        (0, _ajaxError.popupAjaxError)(error);
      } finally {
        this.isUpdating = false;
      }
    }
    static #_13 = (() => dt7948.n(this.prototype, "verifyConnectedMetaMaskAccount", [_object.action]))();
    async verifyConnectedWalletConnectAccount() {
      if (!this.tokens.length) {
        this.dialog.alert((0, _discourseI18n.i18n)("web3.preferences.no_tokens_configured"));
        return;
      }
      this.isUpdating = true;
      try {
        await this.walletConnect.connect(this.selectedChainId);
      } catch (error) {
        (0, _ajaxError.popupAjaxError)(error);
      } finally {
        this.isUpdating = false;
      }
    }
    static #_14 = (() => dt7948.n(this.prototype, "verifyConnectedWalletConnectAccount", [_object.action]))();
    removeWeb3Wallet() {
      this.dialog.yesNoConfirm({
        title: (0, _discourseI18n.i18n)("web3.preferences.remove_wallet_confirmation"),
        didConfirm: async () => {
          this.isUpdating = true;
          try {
            await (0, _ajax.ajax)("/web3/public_keys", {
              type: "DELETE"
            });
            this.currentUser.setProperties({
              web3_public_key: null,
              web3_tokens: []
            });
          } catch (error) {
            (0, _ajaxError.popupAjaxError)(error);
          } finally {
            this.isUpdating = false;
          }
        }
      });
    }
    static #_15 = (() => dt7948.n(this.prototype, "removeWeb3Wallet", [_object.action]))();
  }
  _exports.default = PreferencesWalletsController;
});